import type {AllMarkdownRemark, PageContext} from '../types';

import Feed from '../components/Feed';
import Layout from '../components/Layout';
import Page from '../components/Page';
import Pagination from '../components/Pagination';
// @flow strict
import React from 'react';
import Sidebar from '../components/Sidebar';
import {graphql} from 'gatsby';
import {useSiteMetadata} from '../hooks';
import Title from '../components/Title/Title';

type Props = {
  data: AllMarkdownRemark,
  pageContext: PageContext,
};

const IndexTemplate = ({data, pageContext}: Props) => {
  const {title: siteTitle, subtitle: siteSubtitle} = useSiteMetadata();

  const {currentPage, hasNextPage, hasPrevPage, prevPagePath, nextPagePath} = pageContext;

  const {edges} = data.allMarkdownRemark;
  const pageTitle = currentPage > 0 ? `Posts - Page ${currentPage} - ${siteTitle}` : siteTitle;

  return (
    <Layout title={pageTitle} description={siteSubtitle}>
      <Title />
      <div className="flex space-x-0 md:space-x-4 flex-wrap md:flex-nowrap px-0 px-3 mb-10 md:mb-20">
        <Page>
          <Feed edges={edges} />
          <Pagination
            prevPagePath={prevPagePath}
            nextPagePath={nextPagePath}
            hasPrevPage={hasPrevPage}
            hasNextPage={hasNextPage}
          />
        </Page>
        <Sidebar isIndex />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query IndexTemplate($postsLimit: Int!, $postsOffset: Int!) {
    allMarkdownRemark(
      limit: $postsLimit
      skip: $postsOffset
      filter: {frontmatter: {template: {eq: "post"}, draft: {ne: true}}}
      sort: {order: DESC, fields: [frontmatter___date]}
    ) {
      edges {
        node {
          fields {
            slug
            categorySlug
          }
          frontmatter {
            title
            date
            category
            description
            hero
          }
        }
      }
    }
  }
`;

export default IndexTemplate;
